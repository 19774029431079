.list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  overflow-x: auto;
  margin: 20px 0;
  padding: 0 14px;
}

.list > li {
  margin: 6px 8px;
  padding: 6px 20px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  border-radius: 18px;
  background-color: #292929;
  color: #FFFFFF;
}

li > a {
  color: inherit;
  text-decoration: none;
}

.list > li:nth-child(1) {
  background-color: #FFFFFF;
  color: #707070;
  font-family: 'Konnect-Medium';
  box-shadow: 0px 3px 15px #0000001A;
}

.list > li:nth-child(1) > button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;
  font-family: inherit;
}

.list > li:nth-child(1) svg {
  font-size: 12px;
  margin-right: 10px;
}

.card{
  border-radius: 14px;
  border: 1px solid #EFEFEF;
  margin: 10px;
  padding: 20px;
}
.card h3 {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 16px;
  line-height: 21px;
  color: #0D0C0C;
  display: flex;
  align-items: center;
  margin: 26px 22px;
}

.title > svg {
  margin-right: 10px;
}

.add-link {
  font-family: 'Konnect-Medium';
  font-size: 13px;
  line-height: 17px;
  color: #3393F2;
  border: 1px solid #3393F2;
  border-radius: 15px;
  background-color: transparent;
  padding: 6px 11px;
  margin-top: 26px;
}

.link-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Konnect-Medium';
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.link-container > p:first-child {
  flex: 1;
}

.link-container .editable {
  width: 200px;
  color: #3393F2;
  font-family: 'Konnect-Regular';
  border: 1px solid #EFEFEF;
}

.remove-link {
  color: #3393F2;
  font-size: 18px;
  background-color: transparent;
  border: none;
}

.editable[contenteditable]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #707070;
}

.editable[contenteditable="true"] {
  color: #3393F2;
  border: 1px solid #EFEFEF;
  padding: 1px;
}