.content {
  margin: 0 21px;
  margin-bottom: 100px;
}

.content h1 {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 32px;
  color: #000000;
}

.content h1 svg {
  vertical-align: middle;
  margin-right: 10px;
}

.content h2 {
  font-family: 'Konnect-SemiBold';
  font-size: 20px;
  line-height: 26px;
}

.content h3 {
  font-family: 'Konnect-Medium';
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  margin-bottom: 10px;
}

.content h3 > svg {
  margin-left: 10px;
  vertical-align: middle;
}

.content p {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
  align-items: flex-start;
}

.content a {
  text-decoration: none;
}

.button {
  border-radius: 15px;
  min-width: 89px;
  height: 29px;
  width: auto;
}

.button button {
  padding: 4px 10px;
  font-size: 14px;
  min-width: 89px;
  height: 29px;
}

.card-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.card-header input:checked + span {
  background-color: #ffec60;
}

.typeform-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  text-align: left;
}

.partner svg {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
}

@media (min-width: 992px) {
  .content {
    background-color: #ffffff;
    max-width: 900px;
    margin: auto;
    border-radius: 14px;
    padding: 69px 75px;
    margin-bottom: 100px;
  }

  .content h1 {
    text-align: center;
  }

  .content h1 svg {
    display: none;
  }

  .content h2 {
    font-family: 'Konnect-Medium';
  }
}
