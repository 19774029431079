.card{
  width: -moz-calc(100% - 88px);
  width: -webkit-calc(100% - 88px);
  width: -o-calc(100% - 88px);
  width: calc(100% - 88px);
  max-width: 400px;
  margin: 30px 22px;
  background-color:#1C1C1C;
  color: #FFFFFF;
  padding:22px;
  box-shadow: 0px 3px 15px #0000001A;
  border-radius: 14px;
  display:flex;
  justify-content: space-between;
}

.cardIndex{
    font-family: 'Konnect-Bold';
    font-size: 13px/17px;
    color: #FFEC60;
}

.cardTitle{
  font-family: 'Konnect-Bold';
  font-size: 18px;
  line-height: 23px;
}

.cardDescription{
    font-family: 'Konnect-Regular';
    font-size: 14px;
    line-height: 19px;
}
.image {
  align-self: center;
  height:94px;
}

@media screen and (min-width: 992px) {
    .card{
      width: auto;
      max-width: initial;
    }
}
