.xaxis-label {
  font-family: 'Konnect-Medium' !important;
  font-size: 12px;
  line-height: 16px;
}

.yaxis-label {
  font-family: 'Konnect-Regular' !important;
  font-size: 12px;
  line-height: 16px;
  color: #747474;
}