.title {
  font-family: 'Konnect-Medium';
  font-size: 16px;
  line-height: 21px;
  color: #0D0C0C;
  display: flex;
  align-items: center;
  margin: 26px 22px;
}

.title > svg {
  margin-right: 10px;
}

.card{
  border-radius: 14px;
  border: 1px solid #EFEFEF;
  margin: 10px;
  padding: 20px;
  background-color:white;
}
.card h3 {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.card hr {
  border: 1px solid #EFEFEF;
}

.card p {
  color: #707070;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
}

.experience-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.experience-item p:nth-child(2) {
  color: #000000;
}

.capitalize {
  text-transform: capitalize;
}

@media screen and (min-width: 992px) {
  .card h3 {
    font-family: 'Konnect-Regular';
  }
}