.header {
  height: 67px;
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sticky {
  z-index: 2;
  position:sticky;
  top:0;
  width: 100%;
  background-color: transparent;
  box-shadow: 0px 3px 6px #00000010;
}

.title {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 4px 0;
}