.main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: auto;
  overflow: auto; /* Enable scroll if needed */
}

.header {
  height: 64px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.back-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button > p {
  text-align: left;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-left: 9px;
}

.tab-list {
  flex-shrink: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  margin: 31px 0;
  padding: 0;
}

.tab {
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 48px;
  color: #121212;
}

.tab-active {
  font-family: 'Konnect-Medium';
  position: relative;
}

.tabs{
display:flex;
flex-direction:column;
margin:0;
flex-grow:1;

}

.tab-list {
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
}

.tab {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #141414;
  padding: 5px 19px;
}

.tab.tab-active {
  font-family: 'Konnect-Medium';
  color: #121212;
  background-color: #FFEC60;
  border-radius: 18px;
}

.list {
  margin: 37px;
  padding-inline-start: 0px;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  list-style:none;
}

.list > li {
  margin: 15px 0px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;;
}

.list > li >img {
width: 20px;
}

.bullet{
  background-color: #FFF39F;
  padding: 5px 17px;
  border-radius:15px;
  white-space: nowrap;
}

.black{
  background-color:#000000;
  color:#FFFFFF;
  margin: 0px 0.5ch ;
}

.title{
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  margin:0;
}

.subtitle{
  font-family: 'Konnect-Regular';
  font-size: 12px;
  line-height: 17px;
  margin:0;
}

.li-text{
  flex-grow:1;
  padding: 5px;
}

.li-text b {
  font-weight: bold;
}

.tab-panel{
  flex-shrink: 0;
  margin: auto 0;
}

.link-text{
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 10px;
  padding: 0 35px;
  width: 250px;
}
.link{
  color: black;
}


.footer{
  flex-shrink: 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.sign-up-button{
  margin:auto;
  width: 300px;
}

.sign-up-button-link-container{
  text-align:center;
  text-decoration: none;
  width: 100%;
}

.founder-link{
  color: #3393F2;
}
@media (min-width: 992px) {
  .main{
    position: relative;
    min-height: calc(100vh - 58px);
    background-color: #FAFAFA;
    justify-content: center;
    flex-direction: row;
  }

  .content{
    margin: 30px auto;
    background-color: white;
    border-radius: 14px;
    width: 60%;
    padding: 50px 100px;
  }
  .signup-card{
  }
  .signup-card-header > img {
    width: 100px;
    height: auto;
  }
  .content-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .signup-content{
    display:flex;
    flex-direction:row;
    justify-content: center;
  }
  .signup-content > div{
    flex-grow:1;
    flex-basis:0;
  border: 1px solid #DADADD;
  border-radius: 6px;
  margin: 20px
  }
  .linkedin-list{
  }
.signup-card-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
  .signup-title{
text-align: center;
  font-family: 'Konnect-Medium';
  font-size: 29px;
  line-height: 38px;
  }

  .list-title{

    margin: 30px 20px;
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  }
  .list {
  margin: 15px 37px;
  padding-inline-start: 0px;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  list-style:none;
}

.list > li {
  margin: 5px 0px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;;
}
  /* .modal {
    top: 58px;
    height: calc(100% - 58px);
  }

  .modal-header{
    background-color: #FAFAFA;
  }

  .modal-content {
    box-shadow: none;
    position: relative;
    background-color: #FAFAFA
  }

    .informationMain{
      background-color: white;
      border-radius: 14px;
    height: 80%;
    width: 60%;
    padding: 50px 100px;
    }

    .informationMainContent{

      overflow: scroll;
    }

  .tab-list {
    display: none;
  } */
}
