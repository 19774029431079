.header {
  background-color: #FFFFFF;
  z-index: 10;
  width: 100%;
  box-shadow: 0px 3px 6px #00000010;
  z-index: 2;
  position:sticky;
  top:0;

}

.quizz {
  margin-top: 20px;
}

.container {
  margin: 0 20px;
  margin-bottom: 22px;
  display: flex;
  align-items: flex-start;
}

.container svg {
  font-size: 19px;
  margin-right: 16px;
}

.title {
  flex: 1;
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin: 0;
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}