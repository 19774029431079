.container {
  grid-column: 1 / -1;
  margin-bottom: 20px;
}

.macro-title {
  font-family: 'Konnect-Medium';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 22px 22px 0 22px;
}

.your-level {
  font-family: 'Konnect-Regular';
  font-size: 13px;
  line-height: 17px;
  color: #7c7c7c;
  margin: 5px 22px 14px 22px;
}

.bubble-chart {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

/* Target the tooltip trigger */
.bubble-chart > span {
  grid-row: 1 / -1;
  display: flex;
}

.line {
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  top: 50%;
  position: absolute;
  z-index: 1;
  border-bottom: 1px solid #707070;
}

.bubble-wrapper {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  width: 100%;
  margin: auto;
}

.bubble {
  border-radius: 100%;
  background-color: #292929;
}

.bubble:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.selected {
  background-color: #ffec60;
}
