.informationButton {
  position: fixed;
  z-index: 2;
  bottom: 40px;
  height: 58px;
  font-family: "Konnect-Medium";
  font-size: 15px;
  border-radius: 13px;
  border: 0;
  width: calc(100% - 22px);
  margin: 0 11px;
  text-align: left;
  box-shadow: 0px 3px 20px #00000029;
  animation-name: shaker;
	animation-duration: 1s;
	animation-iteration-count: 3;
	transform-origin:50% 50%;
  animation-delay: 0.3s;
	animation-timing-function: linear;
}

.informationButton:focus {
  border: none;
  outline: none;
}

.informationText {
  display: flex;
  align-items: center;
}

.barChartIcon {
  font-size: 16px;
  margin-right: 16px;
}

@keyframes shaker {
	0% { transform: translate(0, 0); }
	5% { transform: translate(2px, 0); }
	10% { transform: translate(-2px, 0); }
  15% { transform: translate(0, 0); }
  20% { transform: translate(2px, 0); }
	25% { transform: translate(-2px, 0); }
  30% { transform: translate(0, 0); }
  100% { transform: translate(0, 0); }
}
