.tips-tricks {
  background-color: #292929;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 30px;
}

.tips-tricks a {
  text-decoration: none;
  color: white;
}

@media (min-width: 992px) {
  :global(.App) {
    background-color: #fafafa;
  }
  .grid-container {
    display: grid;
    margin: 0 10px;
    max-height: calc(100vh - 58px); /* Size of the navbar */
    grid-template-columns: 460px auto;
    grid-template-rows: 30px 3fr 2fr;
    grid-gap: 10px;
    min-width: 0;
    min-height: 0;
  }

  .profile {
    grid-column: 1 / 2;
    grid-row: 2 / -1;
    overflow: auto;
  }

  .skills {
    grid-column: 2;
    margin-top: 10px;
    grid-row: 2;
    background-color: white;
    padding: 15px;
    border-radius: 14px;
    min-height: 0;
  }

  .peer-reviews {
    grid-column: 2;
    grid-row: 3;
    overflow: auto;
    min-height: 0;
  }

  .tips-tricks {
    grid-column: 1/-1;
    grid-row: 1;
    margin: 0 -10px; /* remove grid gap for this element */
    margin-bottom: -10px;
  }

  .full-grid-height {
    grid-row: 1 / -1;
  }
}
