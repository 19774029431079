.home {
  height: 100vh;
  background-size: 400px;
  background-repeat: no-repeat;
  background-position: center calc(100% + 50px);
}
.title {
  min-height: 78px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'Konnect-Bold';
  font-size: 24px;
  line-height: 78px;
  text-align: center;
}

.list {
  margin: 37px;
  padding-inline-start: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
}

.list > li {
  margin: 23px 0px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.list > li > img {
  margin-right: 13px;
  width: 24px;
}

.start-skill-mapping {
  position: fixed;
  bottom: 60px;
  height: 58px;
  font-family: 'Konnect-Medium';
  font-size: 15px;
  border-radius: 13px;
  border: 0;
  width: 300px;
  margin: 0 41px;
  text-align: left;
  box-shadow: 0px 3px 20px #00000029;
}

.start-skill-mapping-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.link {
  text-decoration: none;
}

.cookie-container {
  width: calc(100% - 14px) !important;
  padding: 0 7px;
}
.cookie-content {
  flex: 1 0 240px !important;
  margin: 15px 0 !important;
}
.cookie-content > a {
  color: #ffffff;
}
.coookie-accept-button {
  margin: 15px 0 !important;
}

@media screen and (min-width: 992px) {
  .content {
    width: 45%;
    background-color: white;
    position: absolute;
    top: 50%;
    padding: 5% 5%;
    border-radius: 14px;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .cookie-content {
    margin-left: 91px !important;
  }
  .title {
    font-size: 30px;
    margin: 0;
    font-family: 'Konnect-Medium';
  }
  .home {
    background-color: #fafafa;
    background-blend-mode: darken;
    background-position: center calc(100% + 80px);
    position: relative;
  }

  .list {
    margin: 0 20%;
    padding-inline-start: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
  }

  .list > li {
    margin: 23px 0px;
    font-family: 'Konnect-Regular';
    font-size: 14px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .start-skill-mapping {
    position: initial;
    height: 58px;
    font-family: 'Konnect-Medium';
    font-size: 15px;
    border-radius: 13px;
    border: 0;
    margin: auto;
    text-align: left;
    box-shadow: none;
    margin-top: 30px;
  }
  .main-background {
    background-color: #fafafa;
  }
}
