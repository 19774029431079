.main {
  margin-bottom: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.container {
  margin: 0 20px;
  margin-bottom: 22px;
  display: flex;
  align-items: flex-start;
}

.container svg {
  font-size: 19px;
  margin-right: 16px;
}

.title {
  flex: 1;
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin: 0;
}

.loadingError{
  margin: 15px;
}

.quizz-container {
  display: flex;
  justify-content: center;
}

.see-my-result {
  width: 300px;
  margin:auto;
  margin-top: 8px;
}

@media screen and (min-width: 992px) {
    .main{
      align-items: stretch;
      flex: 3;
    }

    .card-container {
    flex: 2;
    max-height: calc(100vh - 137px);
    position: relative;
  }

  .card-infos {
    position: fixed;
    margin: 0 22px !important;
    max-height: inherit;
    overflow: auto;
    padding-top: 0 !important;
    height: calc(100vh - 142px);
    display: flex;
    flex-direction: column;
  }

  .see-my-result {
    margin-top: 50px;
  }
}
