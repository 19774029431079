.hidden {
  display: none;
}

.modal {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.modal-content {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  padding: 37px 18px;
  background-color: white;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto; /* Enable scroll if needed */
  box-shadow: 0px 3px 15px #0000001A;
}

@media screen and (min-width: 600px) {
  .modal {
    top: 0;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .modal-content {
    border-radius: 13px;
    width: 60%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

/*
* CSSTransition classnames
*/
.slide-up-enter {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

.slide-up-enter-active {
  bottom: 0;
}

.slide-up-exit {
  bottom: 0;
}

.slide-up-exit-active {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

@media screen and (min-width: 600px) {
  .slide-up-enter {
    bottom: -200vh;
    transition: all 500ms ease-out;
  }

  .slide-up-enter-active {
    bottom: 0;
  }

  .slide-up-exit {
    bottom: 0;
  }

  .slide-up-exit-active {
    bottom: -200vh;
    transition: all 500ms ease-in;
  }
}
