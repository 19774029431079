.date {
  font-family: 'Konnect-Medium';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 20px 24px;
}

@media screen and (min-width: 992px) {
  .date {
    font-family: 'Konnect-Regular';
    margin: 15px 24px 0 24px;
  }
}