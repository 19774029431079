.save-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 20px 16px;
}