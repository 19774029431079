.main {
  margin-bottom: 150px;
  user-select: none;
}

.back-button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 20px 16px;
}

.back-button > svg {
  font-size: 24px;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  display: flex;
  align-items: center;
  margin: 30px 16px;
}

.title > svg {
  margin-right: 16px;
}

.button-container {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  width: 300px;
}
