@font-face {
  font-family: 'Konnect-Medium';
  src: local('Konnect');
  src: url(./assets/fonts/konnect/Konnect-Medium.eot);
  src: url(./assets/fonts/konnect/Konnect-Medium.woff2) format('woff2'),
       url(./assets/fonts/konnect/Konnect-Medium.woff) format('woff'),
       url(./assets/fonts/konnect/Konnect-Medium.otf) format('opentype');
  font-display: block;
}

@font-face {
  font-family: 'Konnect-Bold';
  src: local('Konnect');
  src: url(./assets/fonts/konnect/Konnect-Bold.eot);
  src: url(./assets/fonts/konnect/Konnect-Bold.woff2) format('woff2'),
       url(./assets/fonts/konnect/Konnect-Bold.woff) format('woff'),
       url(./assets/fonts/konnect/Konnect-Bold.otf) format('opentype');
  font-display: block;
}

@font-face {
  font-family: 'Konnect-Regular';
  src: local('Konnect');
  src: url(./assets/fonts/konnect/Konnect-Regular.eot);
  src: url(./assets/fonts/konnect/Konnect-Regular.woff2) format('woff2'),
       url(./assets/fonts/konnect/Konnect-Regular.woff) format('woff'),
       url(./assets/fonts/konnect/Konnect-Regular.otf) format('opentype');
  font-display: block;
}

@font-face {
  font-family: 'Konnect-SemiBold';
  src: local('Konnect');
  src: url(./assets/fonts/konnect/Konnect-SemiBold.eot);
  src: url(./assets/fonts/konnect/Konnect-SemiBold.woff2) format('woff2'),
       url(./assets/fonts/konnect/Konnect-SemiBold.woff) format('woff'),
       url(./assets/fonts/konnect/Konnect-SemiBold.otf) format('opentype');
  font-display: block;
}

body {
  margin: 0;
  font-family: 'Konnect-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-icons {
  vertical-align: middle;
}
.react-ripples{
  width:100%;
}

html, body {
  height: 100%;
}

.no-underline {
  text-decoration: none;
}

.text-centered {
  text-align: center;
}

.margin-bottom {
  margin-bottom: 120px;
}

.Toastify__toast{
  font-family: 'Konnect-Regular';
  font-size: 14px;
  overflow-wrap: nowrap;
  width: 340px;
}
.Toastify__toast--success {
  background: #3393F2 !important;
}


@media screen and (max-width: 992px) {
.App {
  padding-bottom: 1px;
}
}

@media screen and (min-width: 992px) {
  * {
    font-weight: normal;
  }
}
