.main {
  margin: 0 10px;
  max-height: calc(100% - 64px);
  height: 100%;
  display: grid;
  grid-template-rows: 64.5px 50vh 1fr;
  min-width: 0;
  min-height: 0; 
}

.main-header {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 23px;
  margin-left: 10px;
  flex: 1;
}

.date {
  font-family: 'Konnect-Medium';
  font-size: 18px;
  line-height: 23px;
}

.card {
  margin: 21px 10px 0 10px;
  border: 1px solid #EFEFEF;
  border-radius: 14px;
  padding: 17px 12px 0 12px;
  min-height: 0;
}

.card-content {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  margin-bottom: 17px;
}

.chart-title {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.xaxis-label {
  font-family: 'Konnect-Medium' !important;
  font-size: 12px;
  line-height: 16px;
  color: #141414;
}

.yaxis-label {
  font-family: 'Konnect-Regular' !important;
  font-size: 12px;
  line-height: 16px;
  color: #747474;
}

.tab-list {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  margin: 31px 0;
  padding: 0;
}

.tab {
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 48px;
  color: #121212;
}

.tab-active {
  font-family: 'Konnect-Medium';
  position: relative;
}

.tab-active::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 0px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    border: 2px solid #292929;
    background-color: #292929;
}

.card-content > h3 {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.card-content ul {
  padding: 0;
  list-style-type: none;
}

.card-content li, .card-content > p, .card-content i {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.chart-container {
  position: relative;
  height: calc(100% - 20px);
}