.question {
  width: -moz-calc(100% - 44px);
  width: -webkit-calc(100% - 44px);
  width: -o-calc(100% - 44px);
  width: calc(100% - 44px);
  margin: 52px 22px;
  margin-top: 0;
}

.questionTitle {
  font-family: 'Konnect-Bold';
  font-size: 18px;
  line-height: 23px;
}

.questionDeliverables {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 22px;
}

.levelLabelContainer {
  margin: auto;
  margin-top: 0 !important;
  background-color: rgba(255, 255, 255, 0);
}

.levelLabel {
  font-family: 'Konnect-Bold';
  font-size: 18px;
  line-height: 23px;
  border: none !important;
  box-shadow: 0px -4px 16px #00000015;
  border-radius: 10px 10px 0% 0%;
  padding: 14px;
  padding-bottom: 20px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.radioGroup {
  position: relative;
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.radioButton {
  border-radius: 100%;
  z-index: 1;
  display: flex;
  font-family: 'Konnect-Medium';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 10px;
  line-height: 13px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px dashed #6a6a6a;
  transition: 0s border ease-out, 0.5s color ease-out;
  margin-left: 12px;
}

.radioButton:first-child {
  margin-left: 0;
}

.radioButtonTextHidden {
}

.radioButtonSelected.radioButton {
  transition: 0.2s border ease-out 0.2s, 0.5s color ease-out;
  border: 0px dashed rgba(106, 106, 106, 0);
}

.radioButtonSelected:nth-child(1) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink1;
  left: 0;
  background-color: #f4f4f4;
}

.radioButtonSelected:nth-child(2) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink2;
  left: calc((100% - (50px * 6)) / 5 + 50px);
  background-color: #e6e6e6;
}

.radioButtonSelected:nth-child(3) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink3;
  left: calc(((100% - (50px * 6)) / 5 + 50px) * 2);
  background-color: #d0d0d0;
}

.radioButtonSelected:nth-child(4) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink4;
  left: calc(((100% - (50px * 6)) / 5 + 50px) * 3);
  background-color: #a5a5a5;
  color: white;
}
.radioButtonSelected:nth-child(5) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink5;
  left: calc(((100% - (50px * 6)) / 5 + 50px) * 4);
  background-color: #818181;
  color: white;
}

.radioButtonSelected:nth-child(6) ~ .slider {
  transition: transform 1s, 0.2s left 0.05s ease-out,
    background-color 0.4s ease-in;
  animation-name: shrink6;
  left: calc(((100% - (50px * 6)) / 5 + 50px) * 5);
  color: white;
  background-color: #4e4e4e;
}

.radioButtonSelected:nth-child(4) {
  color: white;
}
.radioButtonSelected:nth-child(5) {
  color: white;
}

.radioButtonSelected:nth-child(6) {
  color: white;
}

.radioButtonSelected ~ .slider {
  transition: transform 1s, 0s left 0s ease-out, background-color 0.4s ease-in;
}
.slider {
  animation-name: shrink7;
  border-radius: 50%;
  position: absolute;
  height: 50px;
  left: calc(50% - 25px);
  width: 50px;
  animation-timing-function: ease-in-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transition: transform 1s, 0.2s left 1s ease-out, background-color 0.4s ease-in;
}

@keyframes shrink1 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink2 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink3 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink4 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink5 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink6 {
  30%,
  70% {
    transform: scale(0.5);
  }
}
@keyframes shrink7 {
  30%,
  70% {
    transform: scale(0.5);
  }
}

@media screen and (min-width: 992px) {
  .question {
    margin-top: 30px;
  }

  .questionTitle {
    font-family: 'Konnect-Medium';
  }

  .radioGroup {
    margin-top: 20px;
  }

  .radioButton {
    flex-grow: 1;
    margin: 0 15px;
    border-radius: 10px;
    font-size: 11px;
  }
  .radioButton:first-child {
    margin: 0 15px;
  }

  .slider {
    border-radius: 10px;
    width: calc(100% / 6 - 30px);
  }
  .radioButtonSelected.radioButton {
    transition: 0.2s border ease-out 0.2s, 0.5s color ease-out;
    border: 0px dashed rgba(106, 106, 106, 0);
  }

  .radioButtonSelected:nth-child(1) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink1;
    left: 15px;
    background-color: #f4f4f4;
  }

  .radioButtonSelected:nth-child(2) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink2;
    left: calc((100% / 6) + 15px);
    background-color: #e6e6e6;
  }

  .radioButtonSelected:nth-child(3) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink3;
    left: calc((100% / 6) * 2 + 15px);
    background-color: #d0d0d0;
  }

  .radioButtonSelected:nth-child(4) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink4;
    left: calc((100% / 6) * 3 + 15px);
    background-color: #a5a5a5;
    color: white;
  }
  .radioButtonSelected:nth-child(5) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink5;
    left: calc((100% / 6) * 4 + 15px);
    background-color: #818181;
    color: white;
  }

  .radioButtonSelected:nth-child(6) ~ .slider {
    transition: transform 1s, 0.2s left 0.05s ease-out,
      background-color 0.4s ease-in;
    animation-name: shrink6;
    left: calc((100% / 6) * 5 + 15px);
    color: white;
    background-color: #4e4e4e;
  }
}
