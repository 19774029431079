.container {
  /* display: flex; */
}

.input {
  padding: 5px 12px;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-right: 0px;
}

.input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border: 1px solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.copy-button {
  width: 300px;
}
