.content {
  margin: 0 21px;
  margin-bottom: 100px;
}

.content h1 {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 32px;
  color: #000000;
}

.content h1 svg {
  vertical-align: middle;
  margin-right: 10px;
}

.tab-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.tab {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 5px 13px;
  border-radius: 18px;
}

.tab-active {
  font-family: 'Konnect-Medium';
  background-color: #FFEC60;
}

.terms-content {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.company{
  font-family: 'Konnect-Medium';
}
.terms-content h2 {
  font-family: 'Konnect-Medium';
  font-size: 20px;
}

.terms-content li > a {
  text-decoration: underline;
  color: -webkit-link;
}

@media screen and (min-width: 992px) {
  :global(.App){
    height: initial;
  }
  .content{
    
    background-color:white;
    width: 60%;
    margin:auto;
    padding: 30px;
  }
}