.button {
  font-family: "Konnect-Medium";
  font-size: 15px;
  border: 0;
  padding: 10px 28px;
  max-width: 300px;
  width: 100%;
}
.button:hover{
  cursor: pointer;
}

.buttonContainer:hover{
  cursor: pointer;
}
.buttonContainer {
  display:flex;
  overflow:hidden;
  height: 53px;
  border-radius: 13px;
  max-width: 300px;
  width: 100%;
}

.primary {
  background-color: #FFEC60;
  color: #292929;
}

.secondary {
    background-color: #707070;
    color: #FFFFFF;
}

.tertiary {
  background-color: #3393F2;
  color: #FFFFFF;
}

.button:focus {
  border: none;
  outline: none;
}

.smallButton {
  height: initial;
  width: auto;
  min-width: 5ch;
  margin-left: 10px;
}
.smallButton .button {
  padding: 5px 10px;
  font-size: 13px;
  line-height:17px;
}

.disabled{
  opacity: 50%;
}
