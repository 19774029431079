.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  margin-bottom: 100px;
}

.main-title {
  margin: 0;
  font-family: 'Konnect-SemiBold';
  font-size: 23px;
  line-height: 30px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Konnect-Regular';
  font-size: 13px;
  line-height: 17px;
  color: #7C7C7C;
  text-align: center;
  grid-column: 1 / -1;
  margin: 20px 0;
}

.level {
  font-family: 'Konnect-Regular';
  font-size: 13px;
  line-height: 17px;
  color: #141414;
  grid-row: 2 / 3;
  text-align: center;
  margin: 7px 0;
}

.button {
  position: fixed;
  bottom: 30px;
  width: 90%;
  left: 50%;
  transform: translate(-50%, 0);
  height: inherit;
  text-align: left;
  z-index: 3;
}

.button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.button p {
  margin: 5px auto;
}

.bar-chart-icon {
  margin-right: 16px;
  font-size: 18px;
}

.button-title {
  font-size: 15px;
  font-family: 'Konnect-Medium';
  line-height: 20px;
  color: #000000;
  text-align: center;
}

.button-text {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

@media (min-width: 992px) {
  .grid-container {
    margin-bottom: 0;
  }
}