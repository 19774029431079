.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 22px;
  background-color: white;
}

.main > h1 {
  font-family: 'Konnect-SemiBold';
  font-size: 25px;
  line-height: 32px;
  color: #1C1C1C;
}

.main > h1 {
  font-family: 'Konnect-SemiBold';
  font-size: 25px;
  line-height: 32px;
  max-width: 190px;
  color: #000000;
  margin: 0 0 20px;
  min-height:64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.main > img {
  margin: 40px 0;
  max-width: 100%;
  height: auto;
  max-height : 200px;
}

.main > p {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  white-space: pre-wrap;
}

.btn-container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
@media (min-width: 992px) {

  :global(.App) {
    display: flex;
    flex-direction: column;
  }

    .container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #FAFAFA;
  }
    .main {
    align-self: center;
    max-width: 537px;
    max-height: 672px;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: #FFFFFF;
    padding: 38px 160px 57px;
    border-radius: 14px;
    margin: 20px;
  }

}
