
.container{
  min-height: calc(100vh - 84px);
  padding: 42px;
  display:flex;
  flex-direction:column;
  justify-content: space-evenly;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  text-align: center;
}
.title{
  min-height:78px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-family: 'Konnect-Bold';
  font-size: 30px;
  line-height: 39px;
  text-align: center;
}

.subtitle{
  font-family: 'Konnect-Medium';
  font-size: 17px;
  line-height: 22px;
}

.timeText{
  font-family: 'Konnect-Medium';
  font-size: 13px;
  line-height: 17px;
  margin-top: 0;
}

.content>p{
    margin: 30px 0px;;
}

.stepper{
  display:flex;
  flex-direction:row;
  justify-content: center;
  margin: 15px;
}

.stepper>div{

  margin: 3px;
  height: 7px;
  border-radius: 4px;
}
.yellowDot{
  width: 25px;
  background: #FFEC60;
}

.greyDot{
  background: #707070;
  width: 7px;
}

.btn, .about-you {
  width: 300px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  :global(.App) {
    min-height: 100vh;
  }

  .main {
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: auto;
    max-width: 600px;
  }

  .container {
    background-color: white;
    min-height: 0;
    border-radius: 14px;
  }

  .container > img {
    width: 100px;
    height: auto;
    margin: 0 auto;
  }
}
