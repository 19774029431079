.level-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}

.bar-chart-icon {
  font-size: 18px;
  margin-right: 10px;
}

.level-header h1 {
  text-align: center;
  color: #1C1C1C;
  font-family: 'Konnect-SemiBold';
  font-size: 18px;
  line-height: 23px;
}

.level-list {
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
    flex-grow: 1;
}

.level-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.level-title {
  font-family: 'Konnect-SemiBold';
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 6px;
}

.level-badge {
  border-radius: 14px;
  font-family: 'Konnect-Regular';
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background-color: #707070;
  padding: 5px 10px;
  margin-left: 10px;
}

.level-description {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
}

.level-indicator {
  height: 32px;
  width: 32px;
  min-width: 32px;
  border-radius: 100%;
  margin-right: 13px;
}

.level-item:nth-child(1) .level-indicator {
  border: 1px dashed #6A6A6A;
}

.level-item:nth-child(2) .level-indicator {
  background-color: #F4F4F4;
}

.level-item:nth-child(3) .level-indicator {
  background-color: #E6E6E6;
}

.level-item:nth-child(4) .level-indicator {
  background-color: #D0D0D0;
}

.level-item:nth-child(5) .level-indicator {
  background-color: #A5A5A5;
}

.level-item:nth-child(6) .level-indicator {
  background-color: #818181;
}

.level-item:nth-child(7) .level-indicator {
  background-color: #292929;
}

@media (min-width: 992px) {
  .header-container {
    margin-top: 36px;
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-container > img {
    width: 136px;
    height: auto;
  }

  .header-container > p {
    margin: 0;
    font-family: 'Konnect-Regular';
    font-size: 15px;
    line-height: 20px;
  }

  .level-header {
    margin-bottom: 0;
  }

  .level-header h1 {
    font-family: 'Konnect-Medium';
  }

  .level-item:nth-child(7) {
    margin-bottom: 0;
  }
}