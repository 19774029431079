.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  text-align: center;
}

.title {
  font-family: 'Konnect-SemiBold';
  font-size: 25px;
  line-height: 32px;
  color: #1C1C1C;
  margin-top: 23px;
  margin-bottom: 85px;
}

.image {
  height: auto;
  width: 100%;
  max-width: 240px;
}

.content {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-top: 80px;
  margin-bottom: 31px;
  max-width: 325px;
  width: 100%;
}

.button-container {
  text-decoration: none;
  margin-bottom: 66px;
}

.button {
  width: 296px;
}

.back-button {
  align-self: flex-start;
}