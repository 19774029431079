.card{
  width: -moz-calc(100% - 88px);
  width: -webkit-calc(100% - 88px);
  width: -o-calc(100% - 88px);
  width: calc(100% - 88px);
  max-width: 400px;
  margin: 22px;
  background-color:#1C1C1C;
  color: #FFFFFF;
  padding:22px;
  box-shadow: 0px 3px 15px #0000001A;
  border-radius: 14px;
  display:flex;
}

.cardIndex{
    font-family: 'Konnect-Bold';
    font-size: 13px/17px;
    color: #FFEC60;
}

.cardTitle{
  font-family: 'Konnect-Bold';
  font-size: 20px;
  line-height: 23px;
}

.cardSubTitle {
  font-family: 'Konnect-Medium';
  font-size: 18px;
  line-height: 23px;
}

.cardDescription{
    font-family: 'Konnect-Regular';
    font-size: 14px;
    line-height: 19px;
}
.image {
  align-self: center;
  height:94px;
}

@media (min-width: 992px) {
  .card {
    max-width: 1024px;
  }

  .content {
    flex: 1;
  }
}