.card{
  border-radius: 14px;
  border: 1px solid #EFEFEF;
  background-color:white;
  margin: 10px;
  padding: 20px;
}

.location {
  display:flex;
  align-items:center;
  font-family: 'Konnect-Regular';
  font-size: 13px;
  line-height: 41.67px;
  color: #A5A5A5;
}
.name {
  font-family: 'Konnect-Medium';
  font-size: 28px;
  line-height: 36px;

}

.job-and-edit{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.job {

  font-family: 'Konnect-Regular';
  font-size: 16px;
  line-height: 21px;
}
.description {
  margin-top: 15px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 20px;
}


.editable{
  text-overflow: ellipsis;
  overflow: hidden; 
}
.editable[contenteditable]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #707070;
}
.editable[contenteditable=true]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #3393F2;;
}

.editable[contenteditable="true"] {
  color: #3393F2;
  border: 1px solid #EFEFEF;
  padding: 1px;
}

.saveButton {
  position: sticky;
  bottom: 40px;
  height: 58px;
  font-family: "Konnect-Medium";
  font-size: 15px;
  border-radius: 13px;
  border: 0;
  width: 300px;
  margin: auto;
  text-align: left;
  box-shadow: 0px 3px 20px #00000029;
}
.saveButtonText {
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
}

.tab-list {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  margin: 31px 0;
  padding: 0;
}

.tab {
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 48px;
  color: #121212;
}

.tab-active {
  font-family: 'Konnect-Medium';
  position: relative;
}

.tab-active::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 0px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    border: 2px solid #292929;
    background-color: #292929;
}

.xaxis-label {
  font-family: 'Konnect-Medium' !important;
  font-size: 12px;
  line-height: 16px;
  color:cyan !important;
  overflow-wrap: anywhere !important;
}

.yaxis-label {
  font-family: 'Konnect-Regular' !important;
  font-size: 12px;
  line-height: 16px;
  color:cyan ;
}

.experience{
  display: inline-block;
}

.numberEditable:disabled{
  color:black;
  border:none;
}

.numberEditable:disabled::placeholder {
  color: black;
}

.numberEditable{
  background:none;
  text-align:end;

  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  width: 6ch;

  border: 1px solid #EFEFEF;
  color:#3393F2;
}

.numberEditable::placeholder {
  color: #3393F2;
}
.float-right{
  display: flex;
  justify-content: flex-end;
}
.modify-btn {
  height: 29px;
  width: 66px;
  font-size: 13px;
  font-family: 'Konnect-Medium';
}
.modify-btn button {
  padding: 0;
}