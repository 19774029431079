.hidden {
  display: none;
}

.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.modal-content {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: auto;
  overflow: auto; /* Enable scroll if needed */
  box-shadow: 0px 3px 15px #0000001a;
}

.modal-header {
  height: 64px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.modal-back-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal-back-button > p {
  text-align: left;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-left: 9px;
}

/*
* CSSTransition classnames
*/
.slide-up-enter {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

.slide-up-enter-active {
  bottom: 0;
}

.slide-up-exit {
  bottom: 0;
}

.slide-up-exit-active {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

@media screen and (min-width: 600px) {
  .slide-up-enter {
    bottom: -200vh;
    transition: all 500ms ease-out;
  }

  .slide-up-enter-active {
    bottom: 0;
  }

  .slide-up-exit {
    bottom: 0;
  }

  .slide-up-exit-active {
    bottom: -200vh;
    transition: all 500ms ease-in;
  }
}

.main {
  margin: 0 10px 40px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.main-header {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 23px;
  margin-left: 10px;
}

.date {
  font-family: 'Konnect-Medium';
  font-size: 18px;
  line-height: 23px;
}

.card {
  flex-shrink: 0;
  margin: 21px 10px 0 10px;
  border: 1px solid #efefef;
  border-radius: 14px;
  padding: 17px 12px 0 12px;
}

.card-content {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  margin-bottom: 17px;
}

.chart-title {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.xaxis-label {
  font-family: 'Konnect-Medium' !important;
  font-size: 12px;
  line-height: 16px;
  color: #141414;
}

.yaxis-label {
  font-family: 'Konnect-Regular' !important;
  font-size: 12px;
  line-height: 16px;
  color: #747474;
}

.tab-list {
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  margin: 31px 0;
  padding: 0;
}

.tab {
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 48px;
  color: #121212;
}

.tab-active {
  font-family: 'Konnect-Medium';
  position: relative;
}

.card {
  width: -moz-calc(100% - 88px);
  width: -webkit-calc(100% - 88px);
  width: -o-calc(100% - 88px);
  width: calc(100% - 88px);
  max-width: 400px;
  margin: 22px;
  background-color: #1c1c1c;
  color: #ffffff;
  padding: 22px;
  box-shadow: 0px 3px 15px #0000001a;
  border-radius: 14px;
  display: flex;
}

.cardIndex {
  font-family: 'Konnect-Bold';
  font-size: 13px/17px;
  color: #ffec60;
}

.cardTitle {
  font-family: 'Konnect-Bold';
  font-size: 18px;
  line-height: 23px;
}

.cardDescription {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
}
.cardImage {
  align-self: center;
  height: 94px;
}

.textArea {
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px dashed #707070;
  border-radius: 14px;
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  resize: none;
  outline: none;
  width: -moz-calc(100% - 66px);
  width: -webkit-calc(100% - 66px);
  width: -o-calc(100% - 66px);
  width: calc(100% - 66px);
  margin: 22px;
  padding: 15px;
}
.result-title {
  margin-top: 35px;
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Konnect-SemiBold';
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.informationTitle {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  margin-bottom: 3px;
  margin-top: 0;
}

.informationContent {
  font-family: 'Konnect-Regular';
  font-size: 12px;
  line-height: 17px;
  list-style-type: none;
  padding-inline-start: 0px;
  margin: 0;
}

.informationContent > li:before {
  content: '-';
  text-indent: -5px;
}
.informationSubContent {
  padding-inline-start: 20px;
}

.informationMain {
  margin: 30px 32px 30px 0;
  height: 100%;
}

.informationMain ul {
  list-style: none;
}

.informationMain li {
  position: relative;
  padding: 15px 0 15px 31px;
}

.informationMain li::before {
  content: '';
  position: absolute;
  left: 0;
  height: 24px;
  width: 24px;
  background-image: url('/img/list_bullet.png');
  background-size: 24px;
}

.tabs {
  display: flex;
  flex-direction: column;
  margin: 0;
  flex-grow: 1;
}

.tab-list {
  flex-shrink: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
}

.tab {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #141414;
  padding: 5px 19px;
}

.tab.tab-active {
  font-family: 'Konnect-Medium';
  color: #121212;
  background-color: #ffec60;
  border-radius: 18px;
}

@media (min-width: 992px) {
  .modal {
    top: 58px;
    height: calc(100% - 58px);
  }

  .modal-header {
    background-color: #fafafa;
  }

  .modal-content {
    box-shadow: none;
    position: relative;
    background-color: #fafafa;
  }

  .informationMain {
    background-color: white;
    border-radius: 14px;
    width: 60%;
    padding: 50px 100px;
    height: initial;
    margin: 30px 0px;
  }

  .informationMainContent {
  }

  .tab-list {
    display: none;
  }

  .title {
    min-height: 78px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left -10px;
    font-family: 'Konnect-Bold';
    font-size: 30px;
    line-height: 39px;
    text-align: center;
  }

  .tabs {
    display: flex;
    align-items: center;
    background-color: #fafafa;
    height: calc(100% - 50px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .main {
    max-width: 720px;
  }

  .card {
    max-width: none;
  }

  .white {
    background-color: #ffffff;
    color: #000000;
    flex-direction: column;
  }

  .help-title {
    font-family: 'Konnect-Bold';
    font-size: 20px;
    line-height: 26px;
  }

  .help-title > img {
    width: 61px;
    height: auto;
    filter: invert(1);
    vertical-align: middle;
  }

  .help-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .help-content p {
    flex: 2;
  }

  .help-btn {
    flex: 1;
    margin: 0 10px;
  }

  .informationMain .title {
    min-height: 70px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 0px;
    font-family: 'Konnect-Bold';
    font-size: 30px;
    line-height: 39px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
}
