  .spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.cube1, .cube2, .cube3, .cube4 {
  width: 25px;
  height: 25px;
  border-radius:50%;
  position: absolute;
  top: 0;
  left: 0;
  background: #FFEC60;
  -webkit-animation: sk-cubemove 3.2s infinite ease-out;
  animation: sk-cubemove 3.2s infinite ease-out;
}

.cube2 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.cube3 {
  -webkit-animation-delay: -1.6s;
  animation-delay: -1.6s;
}
.cube4 {
  -webkit-animation-delay: -2.4s;
  animation-delay: -2.4s;
}

@-webkit-keyframes sk-cubemove {
    12.5%{
    -webkit-transform: translateX(21px) translateY(10px) scale(0.4);
  }
  25% { 
    -webkit-transform: translateX(42px) translateY(0px) scale(1);
  }
  37.5%{
    -webkit-transform: translateX(32px) translateY(21px) scale(0.4);
  }
  50% { 
    -webkit-transform: translateX(42px) translateY(42px) scale(1);
  }
  62.5%  {
    -webkit-transform: translateX(21px) translateY(32px) scale(0.4);
  }
  75% { 
    -webkit-transform: translateX(0px) translateY(42px)  scale(1);
  }
   87.5%  {
    -webkit-transform: translateX(10px) translateY(21px) scale(0.4);
  }
  100% { 
    -webkit-transform: translateX(0px) translateY(0px);
  }
}

@keyframes sk-cubemove {
  12.5%{
    transform: translateX(21px) translateY(10px) scale(0.4);
    -webkit-transform: translateX(21px) translateY(10px) scale(0.4);
  }
  25% { 
    transform: translateX(42px) translateY(0px) scale(1);
    -webkit-transform: translateX(42px) translateY(0px) scale(1);
  }
  37.5%{
    transform: translateX(32px) translateY(21px)  scale(0.4);
    -webkit-transform: translateX(32px) translateY(21px) scale(0.4);
  }
  50% { 
    transform: translateX(42px) translateY(42px)  scale(1);
    -webkit-transform: translateX(42px) translateY(42px) scale(1);
  }
  62.5%  {
    transform: translateX(21px) translateY(32px)  scale(0.4);
    -webkit-transform: translateX(21px) translateY(32px) scale(0.4);
  }
  75% { 
    transform: translateX(0px) translateY(42px)  scale(1);
    -webkit-transform: translateX(0px) translateY(42px)  scale(1);
  }
   87.5%  {
    transform: translateX(10px) translateY(21px)  scale(0.4);
    -webkit-transform: translateX(10px) translateY(21px) scale(0.4);
  }
  100% { 
    transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
  }
}