.tab-list {
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.tab {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 5px 13px;
  border-radius: 18px;
}

.tab-active {
  font-family: 'Konnect-Medium';
  background-color: #FFEC60;
}