.main {
  user-select: none;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  display: flex;
  align-items: center;
  margin: 30px 16px;
}

.title > svg {
  margin-right: 16px;
}

.header-btn {
  display: flex;
  justify-content: space-between;
}

.textarea-container > textarea {
  font-family: 'Konnect-Regular';
  font-size: 16px;
  border: 1px solid #DADADD;
  border-radius: 6px;
  height: 150px;
  width: 100%;
  padding:22px;
  margin: 0 22px;
  box-sizing: border-box;
  resize: none;
  max-width: 444px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: -moz-calc(100% - 44px);
  width: -webkit-calc(100% - 44px);
  width: -o-calc(100% - 44px);
  width: calc(100% - 44px);
  max-width: 400px;
}

.textarea-container {
  margin: 0;
}

.textarea-container > h3, .textarea-container > p {
  margin: 22px;
}

.button-container {
  margin-top: 68px;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.btn {
  width: 300px;
}

@media (min-width: 992px) {
  .textarea-container > textarea {
    max-width: 1068px;
  }

  .btn {
    width: 300px;
  }
}
