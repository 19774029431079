.hidden {
  position: absolute;
  opacity:0;
  width:0;
}

.main {
  margin: 0 10px 40px;
  display:flex;
  flex-direction: column;
}

.main-header {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.title {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 23px;
  margin-left: 10px;
}

.btn-group {
    display:flex;
    flex-direction:column;

}

.btn{
    border: 1px solid #707070;
    border-radius: 10px;
    padding:15px;

  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
    margin: 7px;
}

.btn.checked{
    border: none;
    background-color: #FFEC60;
}
.nextButton{
  width: 300px;
  border-radius: 15px;
  margin: auto;
  margin-top: 10px;
}

@media screen and (min-width: 992px) {
  .card{
    margin-left: 10px;
  }
  .btn-group {
    flex-direction: row;
  }
  .main{
    justify-content: space-between;
    height:100%;
  }
  .nextButton{
    align-self: flex-start;
    width: 300px;
    margin-left: 10px;
    margin-top : 2em;
  }
}
