.card {
  border: 1px solid #DADADD;
  background-color: #FFFFFF;
  border-radius: 6px;
  padding: 15px;
  margin: 16px 0;
}

.overflow-auto {
  overflow: auto;
}