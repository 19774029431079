.card {
  margin: 21px 10px 0 10px;
  border: 1px solid #EFEFEF;
  border-radius: 14px;
  width: 400px;
  box-shadow: 0px 10px 30px #00000012;
}

.card:hover{
  cursor:pointer;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-content > svg {
  font-size: 48px;
  margin-left: 30px;
  color: #1C1C1C;
}

.card-content > img {
  width: 136px;
  height: 136px;
  margin-right: 15px;
  margin-top: 13px;
}

.card-title {
  font-family: 'Konnect-SemiBold';
  font-size: 20px;
  line-height: 26px;
  color: #1C1C1C;
  margin-left: 30px;
  margin-bottom: 33px;
}

.card:first-child {
  background-color: #FFEC60;
  height: 100%;
  display: block;
  max-width: 100%;
}

.card:first-child p {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  margin: 0 21px;
  margin-bottom: 23px;
  text-align: left;
}

.card-content:first-child {
  align-items: flex-end;
}

.card-content:first-child > img {
  mix-blend-mode: darken;
}

.peer-reviews-title {
  font-family: 'Konnect-Medium';
  font-size: 25px;
  line-height: 32px;
  color: #000000;
  display: flex;
  align-items: center;
  margin-top: 29px;
  width: 100%;
}

.peer-reviews-title > svg {
  margin-left: 21px;
  margin-right: 10px;
}

.peer-reviews-title > h3 {
  font-size: inherit;
  margin: 0;
}

.peer-reviews-title2 {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0 21px;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.card button {
    min-width: 100%;
    padding: 0;
    height: 236px;
  }

@media (min-width: 992px) {
  .peer-reviews-title > h3 {
    flex: 0 1 auto;
    margin: 0 21px;
  }

  .peer-reviews-title > p {
    flex: 1;
    margin: 0;
  }

  .peer-reviews-title {
    margin-top: 20px;
  }

  .list {
    padding-bottom: 21px;
  }

  .card {
    min-width: 400px;
  }
}
