.btn-start-peer-review {
  border-radius: 29px;
  z-index:1;
  position: fixed;
  right: 9px;
  bottom: 15px;
  height: 56px;
  width: 185px;
  box-shadow: 0px 3px 20px #00000029;
}

.btn-start-peer-review button {
  padding: 0;
}

.btn-content {
  display: flex;
  align-items: center;
}

.btn-content svg {
  font-size: 14px;
  margin: 0 8px 0 18px;
}