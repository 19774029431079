.title{
  margin: 0;

}
.header{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

  .chart-container {
    height: calc(100% - 48px);
  }

  .tabs{
    margin-left: 30px;
  }
  .tab-list {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.tab {
  text-align: center;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 48px;
  color: #121212;
  margin: 0 10px;
}

.tab-active {
  font-family: 'Konnect-Medium';
  position: relative;
}

.tab-active::before {
    content: "";
    position: absolute;
    width: 36px;
    height: 0px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    border: 2px solid #292929;
    background-color: #292929;
}

.modify-btn {
  height: 29px;
  width: 66px;
  font-size: 13px;
  font-family: 'Konnect-Medium';
  text-decoration: none;
}

.modify-btn button {
  padding: 0;
}
.graph-toolbar{
 display: flex;
 flex-direction: row;
 align-items: center;
}

.graph-toolbar > svg {
  margin: 0 10px;
}

.graph-toolbar > a {
  margin: 0 10px;
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .title {
    font-family: 'Konnect-Medium';
    font-size: 25px;
  }

  .modify-btn span {
    font-family: 'Konnect-Regular';
  }

  .share {
    cursor: pointer;
  }
}