.hidden {
  display: none;
}

.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.modal-content {
  position: absolute;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: white;
  margin: auto;
  overflow: auto; /* Enable scroll if needed */
  box-shadow: 0px 3px 15px #0000001A;
}

.modal-header {
  height: 64px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.modal-back-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal-back-button > p {
  text-align: left;
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-left: 9px;
}

/*
* CSSTransition classnames
*/
.slide-up-enter {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

.slide-up-enter-active {
  bottom: 0;
}

.slide-up-exit {
  bottom: 0;
}

.slide-up-exit-active {
  bottom: -100%;
  transition: all 500ms ease-in-out;
}

@media screen and (min-width: 600px) {
  .slide-up-enter {
    bottom: -200vh;
    transition: all 500ms ease-out;
  }

  .slide-up-enter-active {
    bottom: 0;
  }

  .slide-up-exit {
    bottom: 0;
  }

  .slide-up-exit-active {
    bottom: -200vh;
    transition: all 500ms ease-in;
  }
}