.main {
  user-select: none;
}

.result-title {
  margin-top: 35px;
  margin-bottom: 32px;
  text-align: center;
  font-family: 'Konnect-SemiBold';
  font-size: 18px;
  line-height: 23px;
  color: #1C1C1C;
}

.tab-list {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
}

.tab {
  font-family: 'Konnect-Regular';
  font-size: 15px;
  line-height: 20px;
  color: #141414;
  padding: 5px 19px;
}

.tab.tab-active {
  font-family: 'Konnect-Medium';
  color: #121212;
  background-color: #FFEC60;
  border-radius: 18px;
}

@media (min-width: 992px) {
  .main {
    height: calc(100vh - 58px);
  }

  .grid-container {
    height: calc(100vh - 122px);
    max-height: calc(100vh - 122px);
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 100%;
    grid-gap: 15px;
    padding: 0 15px;
  }
}