.navbar {
  height: 58px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  background-color: white;
}

.menu-icon {
  font-size: 19px;
  color: #000000;
}

.title {
  text-align: center;
  font-family: 'Konnect-Medium';
  font-size: 20px;
  line-height: 26px;
  color: #292929;
  text-decoration: none;
}

.avatar {
  border-radius: 100%;
  background-color: #707070;
  width: 25px;
  height: 25px;
}

.sign-in {
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  text-decoration: none;
}

.menu-item {
  font-family: 'Konnect-Regular';
  font-size: 14px;
}

@media (min-width: 992px) {
  .navbar {
    padding: 0 83px;
  }

  .title {
    text-align: left;
    font-family: 'Konnect-Bold';
    font-size: 20px;
    line-height: 26px;
    color: #1F1F1F;
  }

  .menu-icon {
    display: none;
  }

  .sign-in {
    font-family: 'Konnect-Regular';
    opacity: 0.5;
  }
}
