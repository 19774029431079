.container {
  margin: 50px 22px;
}

.container > h1 {
  font-family: 'Konnect-SemiBold';
  font-size: 18px;
  line-height: 23px;
  color: #1C1C1C;
}

.container > p {
  font-family: 'Konnect-Regular';
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option {
  width: 112px;
  height: 52px;
  border: 1px solid #707070;
  background: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 13px 15px;
  font-family: 'Konnect-Medium';
  font-size: 15px;
  line-height: 20px;
}

.option.selected {
  background-color: #FFEC60;
}

.input-container {
  text-align: center;
}

@media (min-width: 992px) {
  .select {
    justify-content: start;
  }
}