.you-tab {
  margin: 30px 15px;
}

.chart-container {
  height: 350px;
}

.yaxis-label {
  color: #747474;
  font-size: 12px;
  font-family: 'Konnect-Regular' !important;
  line-height: 16px;
}

.xaxis-label {
  color: #141414;
  font-family: 'Konnect-Medium' !important;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

@media (min-width: 992px) {
  .you-tab {
    margin: 0 15px;
    display: inline-block;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    overflow: auto;
  }

  .chart-container {
    flex: 0 1 auto;
  }

  .title {
    margin: 0;
    font-family: 'Konnect-SemiBold';
    font-size: 23px;
    line-height: 30px;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tab-list {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    padding: 0;
  }

  .tab {
    font-family: 'Konnect-Regular';
    font-size: 15px;
    line-height: 20px;
    color: #141414;
    padding: 5px 19px;
  }

  .tab.tab-active {
    font-family: 'Konnect-Medium';
    color: #121212;
    background-color: #ffec60;
    border-radius: 18px;
  }
}
